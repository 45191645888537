import axios from "axios";
import { Message } from "element-ui";
import { lineHeightStyle } from "./lineHeight.js";
console.log(lineHeightStyle.whitelist)
    /*富文本编辑图片上传配置*/
const uploadConfig = {
    action: "/api/file/uploadObjectOSS", // 必填参数 图片上传地址
    methods: "POST", // 必填参数 图片上传方式
    name: "img", // 必填参数 文件的参数名
    size: 1024 * 1024 * 2, // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
    accept: "image/png, image/gif, image/jpeg, image/bmp, image/x-icon" // 可选 可上传的图片格式
};

// toolbar工具栏的工具选项（默认展示全部）
const toolOptions =
    [
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        [{lineheight: ["initial", "1", "1.5", "1.75", "2", "3", "4"] }], // 对齐方式
        [{ indent: '-1' }, { indent: '+1' }], // 缩进
        ["bold", "italic", "underline", "strike"],
        [{list: "ordered"}, {list: "bullet"}],
        [{script: "sub"}, {script: "super"}],
        [{indent: "-1"}, {indent: "+1"}],
        [{direction: "rtl"}],
        [{color: []}, {background: []}],
        [{ 'size': ['10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '32px', '48px'] }],
        [{align: []}],
        ["clean"],
        ["link", "image"]
    ];
    // [
    //     ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
    //     ["blockquote", "code-block"], // 引用  代码块
    //     // [{ header: 1 }, { header: 2 }], // 1、2 级标题
    //     [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
    //     [{ script: "sub" }, { script: "super" }], // 上标/下标
    //     [{ indent: "-1" }, { indent: "+1" }], // 缩进
    //     [{ 'direction': 'rtl' }], // 文本方向
    //     [{ size: ["small", false, "large", "huge"] }], // 字体大小
    //     [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
    //     [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
    //     [{ font: [] }], // 字体种类
    //     [{ align: [] }], // 对齐方式
    //     ["clean"], // 清除文本格式
    //     ["link", "image", "video"] // 链接、图片、视频
    // ];

const handlers = {
    image: function image() {
        var self = this;
        console.log("input");
        var fileInput = this.container.querySelector("input.ql-image[type=file]");
        console.log(fileInput)
        if (fileInput === null) {
            fileInput = document.createElement("input");
            fileInput.setAttribute("type", "file");
            // 设置图片参数名
            if (uploadConfig.name) {
                fileInput.setAttribute("name", uploadConfig.name);
            }
            // 可设置上传图片的格式
            fileInput.setAttribute("accept", uploadConfig.accept);
            fileInput.classList.add("ql-image");
            // 监听选择文件
            fileInput.addEventListener("change", async function() {
                console.log(1);
                // 创建formData
                if (uploadConfig.size && fileInput.files[0].size >= uploadConfig.size) {
                    Message.warning("图片大小不能超过2M");
                    fileInput.value = "";
                    return;
                }
                let formData = new FormData();
                formData.append("file", fileInput.files[0]);
                fileInput.value = "";
                //这里 请求
                Message({
                    message: "正在上传"
                });
                try {
                    console.log(uploadConfig.action)
                    const result = await axios.post(uploadConfig.action, formData);
                    Message.closeAll();
                    Message({
                        message: result.message || "上传成功",
                        type: "success"
                    });
                    var picPath = `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${result.data}`;
                    let length = self.quill.getSelection(true).index;
                    //这里很重要，你图片上传成功后，img的src需要在这里添加，res.path就是你服务器返回的图片链接。
                    self.quill.insertEmbed(length, "image", picPath);
                    self.quill.setSelection(length + 1);
                    fileInput.value = "";
                } catch (error) {
                    console.log(error)
                    Message({
                        message: "上传失败",
                        type: "error"
                    });
                }
            });
            this.container.appendChild(fileInput);
        }
        fileInput.click();
    },
    video: function video() {
        var fileInput = this.container.querySelector("input.ql-image[type=file]");
        console.log(fileInput);
    },
    lineheight: function lineheight(value) {
        if (value) {
            this.quill.format('lineHeight', value);
        } else {
            console.log(value);
        }
    }
};

export default {
    placeholder: "请填写公告内容",
    theme: "snow", // 主题

    modules: {
        // ImageExtend: {
        // 	loading: true,
        // 	...uploadConfig,
        // 	size: 2,
        // 	response: (res) => {
        // 		console.log('tag1', res)
        // 		if(res.code !== 100) {
        // 			return ""
        // 		}
        // 		const { host, url } = res.data
        // 	  return `http://${host + url}`
        // 	}
        //   },
        // imageResize: {
        //   displayStyles: {
        //     backgroundColor: "black",
        //     border: "none",
        //     color: "white"
        //   },
        //   modules: ["Resize", "DisplaySize", "Toolbar"]
        // },
        imageDrop: false,
        toolbar: {
            container: toolOptions, // 工具栏选项
            handlers: handlers // 事件重写
        }
    }
};
